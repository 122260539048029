import React, { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { abc } from "./slabsData";
import { Fade } from "react-awesome-reveal";
import Slider from "react-slick";

function SlabsDetails() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
  };

  const [product, setProduct] = useState(null);
  const [location, setLocation] = useLocation();

  useEffect(() => {
    const productName = decodeURIComponent(location.split("/").pop());
    const foundProduct = abc.data.find(product => product.product_name === productName);
    if (foundProduct) {
      setProduct(foundProduct);
    }
  }, [location]);

  if (!product) {
    return <div>Product not found</div>;
  }

  const {
    product_name,
    description,
    primary_colour,
    vein,
    category,
    image_url,
    image_url2,
    slider_img,
    slider_img2,
    slider_img3,
  } = product;

  return (
    <div>
      <Fade duration={2000} triggerOnce>
        <section className="productSection">
          <div className="row" style={{ marginTop: "60px" }}>
            <div id="headingContainer">
              <h2 id="heading">{product_name}</h2>
            </div>
            <div className="col-lg-6" id="productPageImageContainer">

              <Slider className="imageSlider" {...settings}>
                <div>
                  <img className="slabImages" src={product.image_url2}></img>
                </div>
                <div>
                  <img className="slabImages" src={product.image_url3}></img>
                </div>
              </Slider>
            </div>
            <div id="productPageDescriptionContainer" className="col-lg-6">
              <p style={{ fontFamily:'Proxima Nova', margin: "10px" }}>
                {description}
              </p>
              <div className="BorderLine"></div>

          
       <div>
  <div className="row">
    <div className="col-lg-4">
      <div className="info-box">
        <h5 className="info-title">Primary Colour</h5>
        <p className="info-content">{primary_colour}</p>
        <h5 className="info-title">Vein</h5>
        <p className="info-content">{vein}</p>
      </div>
    </div>
    <div className="col-lg-4">
      <div className="info-box">
        <h5 className="info-title">Super Jumbo Slab</h5>
        <p className="info-content">Thickness: 2cm & 3 cm</p>
        <p className="info-content">Size: 138” x 78”</p>
        <p className="info-content">3500 X 2000 mm</p>
      </div>
    </div>
    <div className="col-lg-4">
      <div className="info-box">
        <h5 className="info-title">Cut to Size</h5>
        <p className="info-content">Kitchen</p>
        <p className="info-content">Island</p>
        <p className="info-content">Backsplash</p>
      </div>
    </div>
  
  </div>
  <div className="row">
    <div className="col-lg-4">
      <div className="info-box">
        <h5 className="info-title">Jumbo Slab</h5>
        <p className="info-content">Thickness: 2cm & 3 cm</p>
        <p className="info-content">Size: 127” x 64”</p>
        <p className="info-content">3230 x 1630 mm</p>
      </div>
    </div>
    <div className="col-lg-4">
      <div className="info-box">
        <h5 className="info-title">Finish</h5>
        <p className="info-content">Honed</p>
        <p className="info-content">Leather</p>
        <p className="info-content">Polish</p>
      </div>
    </div>
    <div className="col-lg-4">
      <div className="info-box">
        <h5 className="info-title">Applications</h5>
        <p className="info-content">Kitchen Countertops</p>
        <p className="info-content">Shower Panels</p>
        <p className="info-content">Tubs Surrounds</p>
        <p className="info-content">Bathroom Vanities</p>
        <p className="info-content">Fireplace Surrounds</p>
        <p className="info-content">Bar Tops</p>
        <p className="info-content">Table Tops</p>
        <p className="info-content">Commercial Spaces</p>
      </div>
    </div>
    
  </div>
</div>


            </div>
          </div>
        </section>
      </Fade>
    </div>
  );
}

export default SlabsDetails;
